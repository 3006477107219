import ApiService from "@/core/services/api.service";
import store from "@/core/services/store";
let url = process.env.VUE_APP_API_TENANT;
if (process.env.VUE_APP_VERSION >= 2) {
  url = process.env.VUE_APP_API_ADMIN;
}

ApiService.setHeader("x-api-key", store.getters.apiToken);
if (store.getters.apiTokenPartner) {
  ApiService.setHeader("x-partner-key", store.getters.apiTokenPartner);
}

class Insights {
  get(id) {
    return ApiService.get(url, "insights/" + id);
  }

  getAll(params, cancelToken) {
    let options = new URLSearchParams(params).toString();
    let headers = {};
    if (cancelToken) {
      headers.cancelToken = cancelToken;
    }
    return ApiService.get(url, "insights?" + options, headers);
  }

  store(data) {
    return ApiService.post(url + "/insights", data);
  }

  update(id, data) {
    return ApiService.put(url + "/insights/" + id, data);
  }

  delete(id) {
    return ApiService.delete(url + "/insights/" + id);
  }

  run(id) {
    return ApiService.post(url + "/insights/" + id + "/run", {});
  }
}

export default new Insights();
